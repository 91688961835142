// @flow

import React, { type Element } from "react";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = (): boolean | Element<"h1"> =>
    browser && <h1>404: NOT FOUND</h1>;

export default NotFoundPage;
