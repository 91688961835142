// @flow

import React, { type Node } from "react";

import { Permissions } from "../../api/permissions";
import { History } from "../../containers";
import { usePermissions } from "../../hooks";
import NotFoundPage from "../404";

type Props = {
    id: string,
};

const MaterialHistory = ({ id }: Props): Node => {
    const [canRead, canDownload] = usePermissions([
        Permissions.MATERIAL_READ,
        Permissions.MATERIAL_DOWNLOAD,
    ]);

    if (!canRead || !id) {
        return <NotFoundPage />;
    }

    const [companyId, skuId] = id.split("-");

    return (
        <History
            id={`${companyId}/${skuId}`}
            backUrl={"/materials"}
            label={"Material version history - "}
            base={"material"}
            canDownload={canDownload}
        />
    );
};

export default MaterialHistory;
